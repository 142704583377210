import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Ernesto Celi', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website 🙂', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello there, my name is',
  name: 'Ernesto',
  subtitle: 'I’m a Full Stack Developer',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'ErnestoB&W.jpeg',
  paragraphOne:
    'I’m a coding bootcamp graduate with 3+ years experience as a mechanical engineer. I’m building full-stack web applications in React, Node, Express and MongoDB. I’m also phenomenal at Googling.',
  paragraphTwo:
    'My recent leap into software development has been exciting and I’m eager to keep learning constantly and moving forwards. Currently sharpening my skills in Typescript, GraphQL and Gatsby.',
  paragraphThree: 'Keep scrolling to have a look at some of the things I’ve built',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'devradarhome.png',
    title: 'DevRadar',
    info: 'Inspired by Joppy, DevRadar is a recruitment platform where the job opportunities are filtered to match your profile and preferences.',
    info2:
      'Built in bare bones React with minimal use of libraries. Calling a custom API written in Express to provide authentication, filtering and pagination, database management and even a built-in chat using Socket.io',
    url: 'https://devradarapp.netlify.app/login',
    repo: 'https://github.com/nds-fsd/joppy-frontend', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'todoapptasks.png',
    title: 'To-Do App',
    info: 'Minimalistic app to keep track of your tasks, with customizable categories for easier organization',
    info2:
      'Full-stack web application built using the MERN stack. Have a look at the code below 😀',
    url: 'https://github.com/ernestoceli/todo-app-frontend',
    repo: 'https://github.com/ernestoceli/todo-app-backend', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'ernesto.celi.v@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/ernestoceli/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/ernestoceli',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/ernestoceli',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
